<template>
    <div class="p-4">
<!--      {{ columns }}-->
        <a-table
            toolbar-column-filter
            :columns="columns"
            :source="courses">
<!--            <template #toolbar>-->
<!--              <a-button type="submit" @click="modal = true">-->
<!--                Agregar materia-->
<!--              </a-button>-->
<!--            </template>-->
            <template #toolbar(search)>
                <h2 class="text-2xl px-2">Materias en curso</h2>
            </template>
            <template #toolbar>
                <v-select
                    class="w-48"
                    :loading="loadings.periods"
                    :options="periods"
                    v-model="period"
                    placeholder="Período"
                    :reduce="$0 => $0.value"
                    @input="fetchCourses()" />
            </template>

          <template #td(actions)="{ item }">
                <a-dropdown>
                  <template #menu>
                    <a-dropdown-item @click="withdrawCourse(item)" v-if="item.status === 'assigned'">
                      Retirar materia
                    </a-dropdown-item>
                    <a-dropdown-item @click="withdrawCourse(item)" v-if="item.status === 'withdrawn'">
                      Asignar materia
                    </a-dropdown-item>
                  </template>
                </a-dropdown>
            </template>
        </a-table>
      <a-modal
        :show="modal"
        :title="'Agregar materia'"
        :disabled-confirm="selectedCourse === null"
        @confirm="addCourse"
        @cancel="modal = false"
      >
        <a-select
          :placeholder="'Seleccione una materia'"
          :source="withdrawnCourses.map(value => ({ label: value.course.title, value: value.course.id }))"
          v-model="selectedCourse"/>
      </a-modal>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Vue from "vue";

export default {
    data() {
      return {
        loadings: {
          periods: false,
          courses: false
        },
        query: {
          limit: 15,
          page: 1,
          with: 'course,teacher,section',
          registered: 1
        },
        periods: [],
        period: null,
        modal: false,
        courseList: {},
        selectedCourse: null
      }
    },
    computed: {
        columns() {
            return [
              { title: 'Materia', key: 'course.title', display: true },
              { title: 'Especialidad', key: 'specialization.title', display: true },
              { title: 'Semestre', key: 'course', mutate: ({ key }) => `${key.code.slice(-1)}`, display: true },
              { title: 'Sección', key: 'section.letter_code', display: true },
              { title: 'Estatus', key: 'status', mutate: (key) => this.getStatus(key), display: true },
              { title: 'Acciones', slot: "actions", display: this.user.isAdmin },
            ].filter($0 => $0.display)
        },
        ...mapState({
            user: ({ session }) => session.user,
            student: ({ students }) => students.current,
            currentCourses: ({ students }) => students.courses
        }),
        withdrawnCourses () {
          // this.courseList
          let coursesFormatted = Object.assign([], this.currentCourses)
          let coursesSource = []
          coursesFormatted.forEach((value) => {
            if (value.status === 'withdrawn') coursesSource.push(value)
          })
          return coursesSource
        },
        courses () {
            // let coursesFormatted = JSON.parse(JSON.stringify(this.currentCourses))
            if (this.period) {
              let coursesFormatted = Object.assign([], this.currentCourses)
              let coursesSource = []
              coursesFormatted.forEach((value) => {
                coursesSource.push(value)
              })
              return coursesSource
            } else {
              return []
            }

            // return this.student?.latest_inscription
            //     ?.semester
            //     ?.courses
            //     ?.map($0 => {
            //         const course = {
            //             ...$0,
            //             specialization: this.student?.latest_inscription?.specialization,
            //             section: this.student?.latest_inscription?.section
            //         }
            //
            //         return course
            //     }) ?? []
          // return coursesFormatted

            // return coursesFormatted.map($0 => {
            //     return {
            //         title: $0.title,
            //         specialization: $0.specialization.title,
            //         section: $0.section.letter_code
            //       }
            // }) ?? []
        }
    },
    methods: {
        ...mapActions({
            fetchStudentCourses: 'students/fetchStudentCourses'
        }),
        addCourse() {
          console.log(this.selectedCourse)
        },
        getStatus(key) {
            switch(key.key) {
              case 'assigned': return 'Asignada'
              case 'withdrawn': return 'Retirada'
              case 'withdrawn_by_equivalence': return 'Retirada por equivalencia'
              default: return 'testing'
            }
        },
        fetchCourses() {
            this.fetchStudentCourses({
                id: this.student.id,
                filters: {
                    limit: 0,
                    period: this.period
                }
            })
        },
        withdrawCourse(item) {
            Vue.prototype.$confirm({
                message: `¿Está seguro que desea retirar el curso ${item.course.title} de este estudiante?`,
                button: {
                  no: 'No',
                  yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.students
                            .withdrawCourse(item.student_id, { course_ids: [item.course_id], section_id: item.section_id })
                            .then(() => {
                              if(item.status === 'assigned'){
                                this.$message.success({
                                  title: 'Retiro de materia',
                                  message: 'Se ha retirado la materia satisfactoriamente'
                                })
                              }else{
                                this.$message.success({
                                  title: 'Asignación de materia',
                                  message: 'Se ha asignado la materia satisfactoriamente'
                                })
                              }
                              this.fetchCourses(this.student.id)
                            })
                    }
                }
            })
        }
    },
    watch: {
        student(val) {
            if (val) {
                this.fetchCourses(val.id)
            }
        },
        modal(value) {
          if (value) {
            this.selectedCourse = null
          }
        }

    },
    mounted() {
        this.loadings.periods = !this.loadings.periods
        this.$repository.periods.index({
          limit: 0,
          order_by: 'id',
        })
          .then(({ data }) => {
            this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
          })
          .finally(() => this.loadings.periods = !this.loadings.periods)

        // if (this.student) {
        //     this.fetchCourses(this.student.id)
        // }
    }
}
</script>
